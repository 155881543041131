import React, { createContext, useState, useContext,useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Switched to HashRouter
import Navigation from './components/Navigation';
import Home from './components/HomePage';
import Gallery from './components/GalleryPage';
import Contact from './components/ContactPage';
import Footer from './components/Footer';
import ProjectsPage from './components/ProjectsPage';
import ProjectPage from './components/ProjectPage';
import { faHouse, faUser, faImage, faFolder, faLock,faTasks,faTachometerAlt,faAddressBook,faSignOut, faPeopleGroup, faFolderTree, faPowerOff, faUsers, faPerson, faDashboard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import usePublic from './components/usePublicConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminApp from './AdminApp';
import authProvider from './authProvider';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

function App() {
    const projects = usePublic('/content/projects/projectsConfig.json').filter(project => project.visibility);

    const mainContentStyle = {
        paddingTop: '100px',  // Adjust this value based on the actual height of your navigation bar
        height: '100%'
    };
    const routes = [
        { path: '/', label: 'Home', icon: faHouse },
        // { path: '/gallery', label: 'Gallery',icon:faImage },
        {
            path: '/projects', label: 'Projects', icon: faFolder, standardPage: ProjectPage,
            subPaths: projects.map(project => (
                {
                    label: project.title,
                    path: `/projects/${project.nameid}`,
                    project
                }
            )),
        },
        { path: '/contact', label: 'Contact', icon: faAddressBook },
    ];
    const admin_routes = [
        {
            path: '/admin', label: 'Admin', icon: faTachometerAlt, menu:true,role: 0,
            subPaths: [
                {
                    label: "Dashboard",
                    path: `/admin/dashboard`,
                    role: 0,
                    // icon: faDashboard
                },
                
                {
                    label: "Projects",
                    path: `/admin/projects`,
                    role: 0,
                    // icon: faFolderTree
                },
                {
                    label: "Emails",
                    path: `/admin/emails`,
                    role: 98,
                    // icon: faPeopleGroup
                },
                {
                    label: "Users",
                    path: `/admin/users`,
                    role: 99,
                    // icon: faUsers
                },
                {
                    label: "Profile",
                    path: `/admin/profile`,
                    icon: faUser,
                    role: 0,
                },
                {
                    label: "Logout",
                    path: `/`,
                    icon: faSignOutAlt,
                    logout:true,
                    role: 0,
                    action: () => authProvider.logout().then(() => window.location.href = '/')
                }
            ]
        },
    ];
    return (
        <AuthProvider>
            <Router> {/* Removed style from here as Router does not accept style prop */}
                <div style={mainContentStyle} className={`bg-gradient-to-r from-darkAccent to-accent`}>
                    <Navigation baseRoutes={routes} adminRoutes={admin_routes} />
                    <Routes>
                        <Route path="/" element={<Home />} exact />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/admin/*" element={<AdminApp />} />
                        <Route path="/projects" element={<ProjectsPage projectsPath={'/content/projects/projectsConfig.json'} />} />
                        <Route path="/contact" element={<Contact peoplePath={'/content/people/people.json'} />} />
                        {routes.filter(route => route.subPaths).map(route => route.subPaths.map(subPath =>
                            (<Route path={subPath.path} element={<route.standardPage project={subPath.project} />} />)
                        ))}
                    </Routes>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>

    );
}


// Create an AuthContext with default value false (not logged in)

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                // Fetch permissions when the user logs in
                try {
                    const role = await authProvider.getPermissions();
                    setPermissions(role);
                } catch (error) {
                    setPermissions(null);
                }
            } else {
                setPermissions(null); // Reset permissions if no user is logged in
            }
            setLoading(false);
        });

        return unsubscribe; // Clean up the subscription
    }, []);

    const value = { currentUser, permissions };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
export default App;
